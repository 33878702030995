<template>
  <div class="py-7 my-7 block">
    <h5 class="font-weight-bold text-lg-h3 text-h4 mt-4 text-medium-emphasis">
      Our Tutors
    </h5>
    <p class="text-dark-emphasis">Meet some of our Lifeline tutors</p>

    <div class="d-flex justify-center">
      <v-slide-group>
        <v-slide-group-item>
          <v-card elevation="7" class="pa-5 ma-lg-5 ma-3 tutor-card">
            <v-img
              class="align-end text-white"
              cover
              height="130"
              width="250"
              src="/images/Pic.png"
              alt="images/Pic.png"
            >
            </v-img>
            <v-card-title class="text-h5">Hafiz Shamsiya</v-card-title>
            <v-card-subtitle class="text-body-5">Tutor </v-card-subtitle>
            <v-card-text>
              <p>Degree in Basic Edu. Studies</p>
              <p>University of Dev't Studies</p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="white-darken-4"
                text="Learn More"
                class="text-body-1 bg-green-darken-4"
                block
                @click="tutorOne = true"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="tutorOne"
                class="position-absolute"
                height="100%"
                style="bottom: 0"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4">Motivation</p>

                  <p class="text-medium-emphasis text-start">
                    I am passionate about helping students excel in their
                    academics. Since joining Lifeline in 2019, I have tutored
                    and helped students overcome various academic challenges.
                    Get in touch with Lifeline, let me come over and help your
                    child as well.
                  </p>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-btn
                    color="teal-accent-4"
                    text="Close"
                    variant="text"
                    @click="tutorOne = false"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-slide-group-item>

        <v-slide-group-item>
          <v-card class="pa-5 ma-lg-5 ma-3 tutor-card" elevation="7">
            <v-img
              class="align-end text-white"
              cover
              height="130"
              width="270"
              src="/images/Pic.png"
              alt="images/Pic.png"
            >
            </v-img>
            <v-card-title class="text-h5">Mohammed Mujahid</v-card-title>
            <v-card-subtitle class="text-body-5">Tutor </v-card-subtitle>
            <v-card-text>
              <p>Diploma in Education, UCC</p>
              <p>University of Cape Coast</p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="white-darken-4"
                text="Learn More"
                class="text-body-1 bg-green-darken-4"
                block
                @click="tutorTwo = true"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="tutorTwo"
                class="position-absolute"
                height="100%"
                style="bottom: 0"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4">Motivation</p>

                  <p class="text-medium-emphasis text-start">
                    Teaching students and helping them has always been my
                    passion. Thanks to Lifeline, I now have a platform to reach
                    out and help students who are struggling.
                  </p>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-btn
                    color="teal-accent-4"
                    text="Close"
                    variant="text"
                    @click="tutorTwo = false"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-slide-group-item>

        <v-slide-group-item>
          <v-card class="pa-5 ma-lg-5 ma-3 tutor-card" elevation="7">
            <v-img
              class="align-end text-white"
              cover
              height="130"
              width="270"
              src="/images/Pic.png"
              alt="images/Pic.png"
            >
            </v-img>
            <v-card-title class="text-h5">Shafik Shakun</v-card-title>
            <v-card-subtitle class="text-body-5">Tutor </v-card-subtitle>

            <v-card-text>
              <p>Diploma in Education</p>
              <p>Tamale College of Education</p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="white-darken-4"
                text="Learn More"
                class="text-body-1 bg-green-darken-4"
                block
                @click="tutorThree = true"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="tutorThree"
                class="position-absolute"
                height="100%"
                style="bottom: 0"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4">Motivation</p>

                  <p class="text-medium-emphasis text-start">
                    I am always excited helping students overcome various
                    academic challenges. For me Lifeline is the ideal platform
                    for me to continue doing what I love.
                  </p>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-btn
                    color="teal-accent-4"
                    text="Close"
                    variant="text"
                    @click="tutorThree = false"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-slide-group-item>
        <!-- <v-slide-group-item></v-slide-group-item> -->
      </v-slide-group>
    </div>
    <NuxtLink class="text-white" to="/apply">
      <v-btn class="my-4" size="x-large" color="green-darken-4" variant="flat">
        JOIN LIFELINE <i class="bi-people me-2"></i>
      </v-btn>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: "TutorList",
  data() {
    return {
      reveal: false,
      tutorOne: false,
      mobile: false,
      tutorTwo: false,
      tutorThree: false,
      tutor: [
        {
          id: 1,
          name: "",
          school: "",
          experience: "",
        },

        {
          id: 2,
          name: "",
          school: "",
          experience: "",
        },

        {
          id: 3,
          name: "",
          school: "",
          experience: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.tutor-card {
  width: 300px;
}

@media (max-width: 1028px) {
  .tutor-card {
    width: 260px;
  }
}
</style>
